import React, { Component } from 'react';
import LandingVideo from '../Components/LandingVideo/LandingVideo';
import Editorial from '../Components/Editorial/Editorial';
import QuoteDivider from '../Components/QuoteDivider/QuoteDivider';
import BookSectionItem from '../Components/BookSectionItem/BookSectionItem';

// import book config
import { Smaragdenia, Orkos, Xeirokrotima, Dikaiwsi, Desmos, Arxontisa, Seira1_SexpirikosGrifos, Seira1_XakiDantela, Seira1_MegaliParaskeui } from '../Config/books.config';
import Footer from '../Components/Footer/Footer';
import ScrollToTopOnMount from '../Components/ScrollToTopOnMount/ScrollToTopOnMount';
import Stories from '../Components/Stories/Stories';
import ContactForm from '../Components/ContactForm/ContactForm';

let smaragdeniaConfig = {
    ...Smaragdenia,
};

let orkosConfig = {
    ...Orkos,
};

let xeirokrotimaConfig = {
    ...Xeirokrotima,
};

let dikaiwsiConfig = {
    ...Dikaiwsi,
};

let desmnosConfig = {
    ...Desmos,
};

let arxontisaConfig = {
    ...Arxontisa,
};

let sexpirikosConfig = {
    ...Seira1_SexpirikosGrifos,
};

let dantelaConfig = {
    ...Seira1_XakiDantela,
};

let megaliParaskeuiConfig = {
    ...Seira1_MegaliParaskeui,
}

class HomePage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            smaragdeniaConfig,
            orkosConfig,
            xeirokrotimaConfig,
            dikaiwsiConfig,
            desmnosConfig,
            arxontisaConfig,
            sexpirikosConfig,
            dantelaConfig,
            megaliParaskeuiConfig
        };
    }

    render() {
        return (
            <React.Fragment>
                <ScrollToTopOnMount />
                <LandingVideo hideNav={this.props.hideNav} />
                <QuoteDivider />
                <Editorial />

                <div className="uk-section uk-section-default">
                    <BookSectionItem config={this.state.smaragdeniaConfig} />
                </div>

                <div className="uk-section uk-section-muted">
                    <BookSectionItem config={this.state.orkosConfig} orientation="left" />
                </div>

                <div className="uk-section uk-section-default">
                    <BookSectionItem config={this.state.xeirokrotimaConfig} />
                </div>

                <div className="uk-section uk-section-muted">
                    <BookSectionItem config={this.state.dikaiwsiConfig} orientation="left" />
                </div>

                <div className="uk-section uk-section-default">
                    <BookSectionItem config={this.state.desmnosConfig} />
                </div>

                <div className="uk-section uk-section-muted" style={{
                    paddingBottom: '100px'
                }}>
                    <BookSectionItem config={this.state.arxontisaConfig} orientation="left" />
                </div>

                <div className="uk-section uk-section-default" style={{
                    paddingBottom: '100px'
                }}>
                    <BookSectionItem config={this.state.dantelaConfig} />
                </div>

                <div className="uk-section uk-section-muted" style={{
                    paddingBottom: '100px'
                }}>
                    <BookSectionItem config={this.state.sexpirikosConfig} orientation="left" />
                </div>

                <div className="uk-section uk-section-default" style={{
                    paddingBottom: '100px'
                }}>
                    <BookSectionItem config={this.state.megaliParaskeuiConfig}/>
                </div>

                {/* <div className="uk-section uk-section-default">
                    <Stories />
                </div> */}

                {/* <QuoteDivider muted={false}/> */}

                <div className="uk-section uk-section-default">
                    <ContactForm />

                    <div className='uk-margin-top-large'>
                        <Footer />
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default HomePage;