import React, { Component } from 'react';
import NavigationBar from './Components/Navigation/NavigationBar';
import NavigationLink from './Components/Navigation/NavigationLink';
import NavigationCardLink from './Components/Navigation/NavigationCardLink';

import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import ScrollToTopOnMount from './Components/ScrollToTopOnMount/ScrollToTopOnMount';

import HomePage from './Pages/HomePage';
import NewsFeed from './Pages/NewsFeed';
import Presentation from './Pages/Presentation';
import Bio from './Pages/Bio';

import { Smaragdenia, Orkos, Xeirokrotima, Dikaiwsi, Desmos, Arxontisa, Seira1_SexpirikosGrifos, Seira1_XakiDantela, Seira1_MegaliParaskeui } from './Config/books.config';

import BookPage from './Pages/BookPage';
import Gallery from './Pages/Gallery';

import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

const trackingId = "UA-174272872-1"; // Replace with your Google Analytics tracking ID
const history = createBrowserHistory();

class App extends Component {

  constructor(props) {
    super(props)

    let currentLocation = window.location.pathname;
    let isHome = (currentLocation === '/') || (currentLocation === '/home');

    this.state = {
      navVisible: true,
      scrolled: !isHome,
      loading: true,
    }
  }

  handleDisabled = (e) => {
    e.preventDefault();
  }

  hideNav = (bool) => {
    this.setState({
      // navVisible: bool || false,
      scrolled: bool,
    })
  }

  componentDidMount() {


    // ReactGA.initialize(trackingId);
    // ReactGA.set({
    //   userId: Date.now(),
    //   // any data that is relevant to the user session
    //   // that you would like to track with google analytics
    // });

    // // Initialize google analytics page view tracking
    // history.listen(location => {
    //   ReactGA.set({ page: location.pathname }); // Update the user's current page
    //   ReactGA.pageview(location.pathname); // Record a pageview for the given page
    // });

    window.addEventListener('DOMContentLoaded', this.handleLoad);
  }

  componentWillUnmount() {
    window.removeEventListener('DOMContentLoaded', this.handleLoad)
  }

  handleLoad = () => {
    console.log("Fully loaded");
    this.setState({
      loading: false,
    })
  }

  render() {
    return (
      <React.Fragment>

        {this.state.loading ? (
          <div class="uk-overlay uk-overlay-primary uk-light uk-position-bottom uk-flex uk-flex-center uk-flex-middle" style={{
            width: '100%',
            height: '100%',
            zIndex: '100000',
            backgroundColor: '#ffffff',
          }}>
            <div className='uk-flex uk-flex-column uk-flex-center uk-flex-middle'>
              <p style={{ color: '#00688B' }}>Γίνεται φόρτωση της ιστοσελίδας. Παρακαλώ περιμένετε...</p>
              <span uk-spinner="ratio: 4.5" className="uk-margin-top" style={{ color: '#00688B' }}></span>
            </div>
          </div>
        ) : (null)}
        <ScrollToTopOnMount />

        <Router history={history}>
          {/* <NavigationBar visible={this.state.navVisible}> */}
          <NavigationBar scrolled={this.state.scrolled} visible={true}>
            <NavigationLink>
              <Link to="/"> <span className='font-cali text-md uk-button-text uk-padding-small'>Αρχική</span></Link>
            </NavigationLink>

            <NavigationLink>
              <a href="/books" onClick={this.handleDisabled}><span className='font-cali text-md uk-button-text uk-padding-small uk'>Συγγραφικό Έργο</span></a>
              <div className="uk-navbar-dropdown cu-dropdown-full uk-width-1-1 uk-position-fixed uk-visible@m">
                <ul className="uk-nav uk-navbar-dropdown-nav uk-flex uk-flex-center uk-flex-around uk-flex-wrap">
                  <NavigationCardLink {...Smaragdenia} />
                  <NavigationCardLink {...Orkos} />
                  <NavigationCardLink {...Xeirokrotima} />
                  <NavigationCardLink {...Dikaiwsi} />
                  <NavigationCardLink {...Desmos} />
                  <NavigationCardLink {...Arxontisa} />
                  <NavigationCardLink {...Seira1_XakiDantela} />
                  <NavigationCardLink {...Seira1_SexpirikosGrifos} />
                  <NavigationCardLink {...Seira1_MegaliParaskeui} />
                </ul>
              </div>

              <ul class="uk-nav-sub uk-hidden@m" style={{
                marginLeft: '20px',
              }}>
                <NavigationCardLink {...Smaragdenia} />
                <NavigationCardLink {...Orkos} />
                <NavigationCardLink {...Xeirokrotima} />
                <NavigationCardLink {...Dikaiwsi} />
                <NavigationCardLink {...Desmos} />
                <NavigationCardLink {...Arxontisa} />
                <NavigationCardLink {...Seira1_XakiDantela} />
                <NavigationCardLink {...Seira1_SexpirikosGrifos} />
                <NavigationCardLink {...Seira1_MegaliParaskeui} />
              </ul>
            </NavigationLink>

            <NavigationLink>
              <Link to="/newsfeed"><span className='font-cali text-md uk-button-text uk-padding-small'>Νέα</span></Link>
            </NavigationLink>

            <NavigationLink>
              <a href="/presentation" onClick={this.handleDisabled}><span className='font-cali text-md uk-button-text uk-padding-small uk'>Βιβλιοπαρουσιάσεις</span></a>
              <div className="uk-navbar-dropdown cu-dropdown-full uk-width-1-1 uk-position-fixed uk-visible@m">
                <ul className="uk-nav uk-navbar-dropdown-nav uk-flex uk-flex-center uk-flex-around">
                  <NavigationCardLink presentation={true} {...Smaragdenia} />
                  <NavigationCardLink presentation={true} {...Orkos} />
                  <NavigationCardLink presentation={true} {...Xeirokrotima} />
                  <NavigationCardLink presentation={true} {...Dikaiwsi} />
                  <NavigationCardLink presentation={true} {...Desmos} />
                  <NavigationCardLink presentation={true} {...Arxontisa} />
                  <NavigationCardLink presentation={true} {...Seira1_XakiDantela} />
                  <NavigationCardLink presentation={true} {...Seira1_SexpirikosGrifos} />
                  <NavigationCardLink presentation={true} {...Seira1_MegaliParaskeui} />
                </ul>
              </div>
              <ul class="uk-nav-sub uk-hidden@m" style={{
                marginLeft: '20px',
              }}>
                <NavigationCardLink presentation={true} {...Smaragdenia} />
                <NavigationCardLink presentation={true} {...Orkos} />
                <NavigationCardLink presentation={true} {...Xeirokrotima} />
                <NavigationCardLink presentation={true} {...Dikaiwsi} />
                <NavigationCardLink presentation={true} {...Desmos} />
                <NavigationCardLink presentation={true} {...Arxontisa} />
                <NavigationCardLink presentation={true} {...Seira1_XakiDantela} />
                <NavigationCardLink presentation={true} {...Seira1_SexpirikosGrifos} />
                <NavigationCardLink presentation={true} {...Seira1_MegaliParaskeui} />
              </ul>
            </NavigationLink>

            <NavigationLink>
              <Link to="/gallery"><span className='font-cali text-md uk-button-text uk-padding-small'>Photo Gallery</span></Link>
            </NavigationLink>

            <NavigationLink>
              <Link to="/bio"><span className='font-cali text-md uk-button-text uk-padding-small'>Για ΄μένα...</span></Link>
            </NavigationLink>
          </NavigationBar>

          <Switch>

            <Route path="/newsfeed">
              <NewsFeed />
            </Route>

            <Route path="/bio">
              <Bio />
            </Route>

            <Route exact path="/book/1">
              <BookPage {...Smaragdenia} />
            </Route>

            <Route exact path="/book/2">
              <BookPage {...Orkos} />
            </Route>

            <Route exact path="/book/3">
              <BookPage {...Xeirokrotima} />
            </Route>

            <Route exact path="/book/4">
              <BookPage {...Dikaiwsi} />
            </Route>

            <Route exact path="/book/5">
              <BookPage {...Desmos} />
            </Route>

            <Route exact path="/book/6">
              <BookPage {...Arxontisa} />
            </Route>

            <Route exact path="/book/7">
              <BookPage {...Seira1_SexpirikosGrifos} />
            </Route>

            <Route exact path="/book/8">
              <BookPage {...Seira1_XakiDantela} />
            </Route>

            <Route exact path="/book/9">
              <BookPage {...Seira1_MegaliParaskeui} />
            </Route>

            <Route exact path="/presentation/1">
              <Presentation playlists={Smaragdenia.playlists} id='1' />
            </Route>

            <Route exact path="/presentation/2">
              <Presentation playlists={Orkos.playlists} id='2' />
            </Route>

            <Route exact path="/presentation/3">
              <Presentation playlists={Xeirokrotima.playlists} id='3' />
            </Route>

            <Route exact path="/presentation/4">
              <Presentation playlists={Dikaiwsi.playlists} id='4' />
            </Route>

            <Route exact path="/presentation/5">
              <Presentation playlists={Desmos.playlists} id='5' />
            </Route>

            <Route exact path="/presentation/6">
              <Presentation playlists={Arxontisa.playlists} id='6' />
            </Route>

            <Route exact path="/presentation/7">
              <Presentation playlists={Seira1_SexpirikosGrifos.playlists} id='7' />
            </Route>

            <Route exact path="/presentation/8">
              <Presentation playlists={Seira1_XakiDantela.playlists} id='8' />
            </Route>

            <Route exact path="/presentation/8">
              <Presentation playlists={Seira1_MegaliParaskeui.playlists} id='9' />
            </Route>

            <Route exact path="/gallery">
              <Gallery />
            </Route>

            <Route path="/">
              <HomePage hideNav={this.hideNav} />
            </Route>

          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;